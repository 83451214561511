import React, { Fragment } from "react";
import { Link } from "gatsby";
import { Popover, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import QuizMeLogo from "../images/quizme-logo-full.png";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const navigation = [
  // { name: "API", href: "https://astounding-artist-6735.ck.page/4d3b04b288" },
  // {
  //   name: "Download",
  //   href: "https://chrome.google.com/webstore/detail/quiz-me-ai-remember-what/ekcjbijhkfodohbpmenhiiiopfopgkah",
  // },
];

export default function Hero() {
  return (
    <div className="relative bg-white overflow-hidden">
      <div className="max-w-7xl mx-auto">
        <Popover>
          {({ open }) => (
            <>
              <div className="relative pt-6 pb-4 px-4 sm:px-6 lg:px-8">
                <nav
                  className="relative flex items-center justify-between sm:h-10 lg:justify-start"
                  aria-label="Global"
                >
                  <div className="flex items-center flex-grow flex-shrink-0 lg:w-auto">
                    <div className="flex items-center justify-between w-full md:w-auto">
                      <Link to="/">
                        <span className="sr-only">Workflow</span>
                        <img
                          className="h-8 w-auto sm:h-10"
                          src={QuizMeLogo}
                          alt="Quiz Me Ai"
                        />
                      </Link>
                      <div className="-mr-2 flex items-center md:hidden">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-500">
                          <span className="sr-only">Open main menu</span>
                          <MenuIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                  <div className="hidden md:block md:ml-10 md:pr-4 md:space-x-8">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className="font-medium text-gray-500 hover:text-gray-900"
                      >
                        {item.name}
                      </a>
                    ))}
                    <a
                      href="/#love"
                      className="font-medium text-pink-600 hover:text-pink-500"
                    >
                      ❤️ Love
                    </a>
                    {/* <a
                      href="/learn"
                      className="font-medium text-pink-600 hover:text-pink-500"
                    >
                      📖 How to Learn
                    </a>
                    <a
                      href="/pricing"
                      className="font-medium text-pink-600 hover:text-pink-500"
                    >
                      💳 Pricing
                    </a> */}
                    <a
                      href="https://quizme.ai/mission"
                      className="font-medium text-pink-600 hover:text-pink-500"
                    >
                      Mission
                    </a>
                    <a
                      href="https://t.me/quizmeaibot"
                      className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-600 hover:bg-pink-700"
                    >
                      Try Free
                    </a>
                    {/* <AnchorLink
                      to="https://t.me/quizmeaibot"
                      title="Try It"
                      className="ml-8 whitespace-nowrap inline-flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-600 hover:bg-pink-700"
                    >
                      Try Free
                    </AnchorLink> */}
                  </div>
                </nav>
              </div>

              <Transition
                show={open}
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  static
                  className="relative z-10 -top-6 inset-x-0 p-2 -translate-y-12 transition transform origin-top-right md:hidden"
                >
                  <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="px-5 pt-4 flex items-center justify-between">
                      <div>
                        <img className="h-8 w-auto" src={QuizMeLogo} alt="" />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-pink-500">
                          <span className="sr-only">Close main menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                    <div className="px-2 pt-2 pb-3 space-y-1">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className="block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50"
                        >
                          {item.name}
                        </a>
                      ))}
                    </div>
                    {/* <a
                      href="https://dashboard.quizme.ai"
                      className="block w-full px-5 py-3 text-center font-medium text-pink-600 bg-gray-50 hover:bg-gray-100"
                    >
                      Log in
                    </a> */}
                    <AnchorLink
                      to="/#pricing"
                      title="Pricing"
                      className="w-full flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-pink-600 hover:bg-pink-700"
                    >
                      Sign up
                    </AnchorLink>
                  </div>
                </Popover.Panel>
              </Transition>
            </>
          )}
        </Popover>
      </div>
    </div>
  );
}
