import React from "react"
import { useEffect } from "react";
import { init } from "@amplitude/analytics-browser"

import Header from "./header"
import Footer from "./footer"
import Banner from "./banner"

const Layout = ({ children }) => {
  useEffect(() => {
    init(process.env.GATSBY_AMPLITUDE_API_KEY);
  },[]);

  return (
    <>
      {/* <Banner /> */}
      <Header />
      <main>{children}</main>
      <Footer />
    </>
  )
}

export default Layout
